import type { AbstractIntlMessages } from "next-intl";

const interopRequireDefault = (
  obj: Record<string, string | Record<string, string>>
) => {
  if ("default" in obj) {
    return obj.default as AbstractIntlMessages;
  }
  return obj as AbstractIntlMessages;
};

export async function fetchServerTranslations(locale: string) {
  const messages = interopRequireDefault(
    await import(`../public/locales/${locale}/common.json`)
  );

  return {
    messages,
  };
}
